<template>
  <div class="form">
    <el-form :model="form" :rules="rules" ref="rulesForm" label-width="120px">
      <el-form-item label="政策标题:" prop="title" class="lager-input">
        <el-input v-model="form.title" placeholder="请输入" />
      </el-form-item>
      <el-form-item label="政策类别:" prop="policyType">
        <my-radio-multi
          typeCode="policy_type"
          valueField="codeId"
          labelField="codeName"
          :selectedKey="form.policyType"
          :isShowAllTag="false"
          :isShowCollapseBtn="false"
          :value.sync="form.policyType"
          @callback="values => onChangeMyRadioMulti('policyType', values)"
        ></my-radio-multi>
      </el-form-item>
      <el-form-item label="发布机构:" prop="postAgency" class="lager-input">
        <el-input v-model="form.postAgency" placeholder="请输入" />
      </el-form-item>
      <el-form-item
        label="发文机构类型:"
        prop="institutionType"
        class="lager-input"
      >
        <my-checkbox
          typeCode="026"
          :selectedKeys="form.institutionTypes"
          :value.sync="form.institutionTypes"
          :isShowAllTag="false"
        ></my-checkbox>
      </el-form-item>
      <el-form-item label="发文字号:" prop="postName" class="lager-input">
        <el-input v-model="form.postName" placeholder="请输入" />
      </el-form-item>
      <el-form-item label="发文网址:" prop="policyUrl" class="lager-input">
        <el-input v-model="form.policyUrl" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="发文日期:">
        <data-time
          @dateChange="onChangeDataTime"
          :parentYear="form.year"
          :parentMonth="form.month"
          :parentDay="form.day"
        />
      </el-form-item>
      <el-form-item label="发文地区:">
        <cascader-area
          :isShowDistrict="false"
          :country="form.province"
          :province="form.city"
          @searchCountry="val => onChangeArea('province', val)"
          @searchProvince="val => onChangeArea('city', val)"
        />
      </el-form-item>
      <el-form-item
        label="政策简介:"
        prop="policyInto"
        class="is-required lager-input"
      >
        <el-input
          v-model="form.policyInto"
          placeholder="请输入"
          type="textarea"
          :autosize="{ minRows: 10 }"
        ></el-input>
      </el-form-item>
      <el-form-item label="政策有效期:" class="is-required lager-input">
        <el-row style="width: 671px">
          <el-col :span="4"
            ><el-radio v-model="form.expirationDate" label="0"
              >长期有效</el-radio
            ></el-col
          >
          <el-col :span="20">
            <div class="col_flex-row">
              <el-radio v-model="form.expirationDate" label="1"></el-radio>
              <el-form-item
                prop="expirationDateRange"
                :rules="[
                  {
                    // 自定义表单项的验证规则
                    validator: (rules, value, callback) => {
                      validatorFormItem(value, callback);
                    },
                    trigger: 'change',
                    required: false
                  }
                ]"
              >
                <el-date-picker
                  v-model="form.expirationDateRange"
                  value-format="yyyy-MM-dd"
                  type="daterange"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  @change="val => onChangeDate('expirationDateRange', val)"
                  style="width: 100%"
                  :disabled="form.expirationDate != 1"
                >
                </el-date-picker>
              </el-form-item>
            </div>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item label="政策文件:" prop="policyFiles">
        <div
          class="file_flex"
          v-for="(el, i) in form.policyFiles"
          :key="i"
          style="display: flex; margin-bottom: 16px"
        >
          <div
            class="name_form"
            style="width: 50%; display: flex; justify-content: space-between"
          >
            <el-input
              v-model="form.policyFiles[i]"
              placeholder="请上传产品附件"
            />
            <el-upload
              action
              class="upload-demo"
              ref="upload"
              :show-file-list="false"
              :before-upload="beforeUploadprods"
              :http-request="
                res => {
                  return uploadprods(res, i);
                }
              "
            >
              <el-button
                style="position: relative; left: -76px"
                slot="trigger"
                size="mini"
                type="primary"
                plain
                >上传附件</el-button
              >
            </el-upload>
          </div>
          <div style="display: flex; font-size: 24px">
            <span v-if="i == 0" class="add-btn" @click="addUpload"
              ><i class="el-icon-circle-plus"></i
            ></span>
            <span v-if="i > 0" class="del-btn" @click="delUpload(i)"
              ><i class="el-icon-delete"></i
            ></span>
            <el-link
              v-if="el"
              icon="el-icon-download"
              class="down-btn"
              :underline="false"
              @click="downloadFile(el)"
            ></el-link>
          </div>
        </div>
        <span style="color: #909399"
          >支持doc、docx、xls、xlsx、ppt、pptx、pdf格式</span
        >
      </el-form-item>
      <!-- 政策类别小于10显示 -->
      <!-- 奖补信息 -->
      <div v-if="isShowReward">
        <title-bar title="奖补信息" style="margin-bottom: 20px"></title-bar>
        <el-form-item
          label="奖补方式:"
          prop="rewardMethods"
          class="is-required"
        >
          <my-checkbox
            typeCode="reward_method"
            valueField="codeId"
            labelField="codeName"
            :selectedKeys="form.rewardMethods"
            :value.sync="form.rewardMethods"
            :isShowAllTag="false"
          ></my-checkbox>
        </el-form-item>
        <el-form-item
          label="申报时间:"
          prop="declareDateRange"
          class="lager-input"
        >
          <el-date-picker
            v-model="form.declareDateRange"
            value-format="yyyy-MM-dd"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            style="width: 671px"
            @change="val => onChangeDate('declareDateRange', val)"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="申报地区:">
          <cascader-area
            :isShowDistrict="false"
            :country="form.declareProvince"
            :province="form.declareCity"
            @searchCountry="val => onChangeArea('declareProvince', val)"
            @searchProvince="val => onChangeArea('declareCity', val)"
          />
        </el-form-item>
        <el-form-item
          label="申报条件："
          prop="declareCondition"
          class="is-required lager-input"
        >
          <el-input
            v-model="form.declareCondition"
            placeholder="请输入"
            type="textarea"
            :autosize="{ minRows: 10 }"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="奖补内容："
          prop="rewardContent"
          class="is-required lager-input"
        >
          <el-input
            v-model="form.rewardContent"
            placeholder="请输入"
            type="textarea"
            :autosize="{ minRows: 10 }"
            class="lager-input"
          ></el-input>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script>
import UpLoadImage from "../Product/UpLoadImage";
import uploadFile from "./uploadFile";
import CascaderArea from "@/components/CascaderArea.vue";
import DataTime from "@/components/DataTime.vue";
import MyCheckbox from "@/components/MyFormSearch/MyCheckbox";
import MyRadioMulti from "@/components/MyFormSearch/MyRadioMulti";
import TitleBar from "@/components/TitleBar.vue";
import axios from "axios";
import { URL } from "../../../config";
import cos from "../../utils/cos";
import { downFile } from "@/utils/tool";
export default {
  name: "BasicInfo",
  components: {
    CascaderArea,
    DataTime,
    UpLoadImage,
    uploadFile,
    MyCheckbox,
    MyRadioMulti,
    TitleBar
  },
  computed: {
    personType() {
      return Number(this.$route.query.personType);
    },
    policyId() {
      return Number(this.$route.query.id);
    },
    editInfo() {
      return this.$route.params.editInfo;
    },
    //是否展示奖励信息
    isShowReward: function() {
      return this.policyTypeValue && this.policyTypeValue <= 10;
    }
  },

  watch: {
    //有效期限onchange清空
    "form.expirationDate": function(val) {
      if (val === "0") {
        this.form.expirationDateRange = [];
        this.form.expirationStartDate = "";
        this.form.expirationEndDate = "";
      }
    },
    isShowReward: function(val) {
      if (!val) {
        this.clearRewardData();
      }
    }
  },

  data() {
    return {
      //政策类别value 主要用于判断奖补信息是否显示
      policyTypeValue: null,
      form: {
        title: "", //标题
        policyType: "", //政策类型
        postAgency: "", //发布机构
        institutionTypes: [], //发布机构类型
        postName: "", //发文字号
        policyUrl: "", //发文网址
        articeDate: "", //发文日期
        policyInto: "", //政策简介
        expirationDate: "0", //政策有效期
        declareCondition: "", //申报条件
        rewardMethods: [], //奖补方式
        rewardContent: "", //奖补内容
        country: "", //发文省市区
        province: "",
        city: "",
        declareCountry: "", //申报省市区
        declareProvince: "",
        declareCity: "",
        policyFiles: [""], //文件路径数组

        //自定义时间数组
        //政策有效期限
        expirationDateRange: [],
        //申报时间
        declareDateRange: []
      },
      rules: {
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],
        policyInto: [
          { required: true, message: "请输入政策简介", trigger: "blur" }
        ],
        rewardMethods: [
          { required: true, message: "请选择奖补方式", trigger: "change" }
        ],
        declareCondition: [
          { required: true, message: "请输入申报条件", trigger: "blur" }
        ],
        rewardContent: [
          { required: true, message: "请输入奖补内容", trigger: "blur" }
        ]
      },
      loading: false
    };
  },

  methods: {
    //清理表单奖励数据
    clearRewardData() {
      this.form.rewardMethods = [];
      this.form.declareDateRange = [];
      this.form.declareStartTime = null;
      this.form.declareEndTime = null;
      this.form.declareProvince = "";
      this.form.declareCity = "";
      this.form.declareCondition = "";
      this.form.rewardContent = "";
    },
    //下载文件
    downloadFile(fileName) {
      //调用下载
      downFile(
        { urlStr: `http://${fileName}` }, // 后台需要的参数
        "/public/downloadUrlFile", // 路径
        "application/msword", // 下载的文件类型
        fileName //下载文件名称
      );
    },
    //期限范围校验
    validatorFormItem(value, callback) {
      //如果expirationDate为1 则期限范围必填
      if (this.form.expirationDate == "1" && _.isEmpty(value)) {
        callback(new Error("请选择有效期限"));
      }

      callback();
    },
    //政策类别onchange
    onChangeMyRadioMulti(fieleName, values) {
      this.policyTypeValue = values ? Number(values.node.codeId) : null;
    },
    onChangeDataTime(year, month, day) {
      this.form.year = year;
      this.form.day = day;
      this.form.month = month;
    },
    //日期onchange
    onChangeDate(type, val) {
      this.form[type] = val;
      switch (type) {
        case "expirationDateRange":
          this.form["expirationStartDate"] = !_.isEmpty(val) ? val[0] : null;
          this.form["expirationEndDate"] = !_.isEmpty(val) ? val[1] : null;
          break;
        case "declareDateRange":
          this.form["declareStartTime"] = !_.isEmpty(val) ? val[0] : null;
          this.form["declareEndTime"] = !_.isEmpty(val) ? val[1] : null;
          break;
        default:
          break;
      }
    },
    //表单验证
    validateForm() {
      let isValid = false;
      this.$refs.rulesForm.validate(valid => {
        isValid = valid;
      });
      return isValid;
    },
    onChangeArea(fieldName, val) {
      this.form[fieldName] = val;
    },
    //附件新增
    addUpload() {
      this.form.policyFiles.push("");
    },
    //附件删除
    delUpload(index) {
      this.form.policyFiles.splice(index, 1);
    },
    //详情获取 渲染基本信息数据
    handleFormRender(data) {
      let newFormData = {
        ...data,
        policyFiles: data.policyFiles ? data.policyFiles : [""],
        rewardMethods: data.rewardMethods ? data.rewardMethods : [],
        institutionTypes: data.institutionTypes ? data.institutionTypes : [],
        policyType: data.policyType ? Number(data.policyType) : ""
      };
      this.form = newFormData;
      this.policyTypeValue = Number(this.form.policyType);
      //有效期限赋值
      if (Number(data.expirationDate)) {
        this.$set(this.form, "expirationDateRange", [
          data.expirationStartDate,
          data.expirationEndDate
        ]);
      }
      //申报期限赋值
      if (data.declareStartTime || data.declareEndTime) {
        this.$set(this.form, "declareDateRange", [
          data.declareStartTime,
          data.declareEndTime
        ]);
      }
    },
    //文件上传相关
    //文件上传返回的参数
    fileKey(val) {
      this.form.policyFileName = val;
      this.form.policyFileNames = this.form.policyFileName.substring(
        this.form.policyFileName.lastIndexOf("/") + 1
      );
    },
    //文件上传成功返回的名字
    successUpload(val) {
      // this.form.policyFileName = val;
    },
    // 下载附件
    // async downloadFile() {
    //   // const URL = "http://192.168.0.12:8085/";
    //   const url = this.form.policyFileName;
    //   axios({
    //     method: "GET",
    //     url: `${URL}/public/downloadUrlFile`,
    //     params: { urlStr: url },
    //     responseType: "blob",
    //     headers: {
    //       // 设置请求头
    //       Authorization: this.Authorization
    //     }
    //   })
    //     .then(res => {
    //       let blob = new Blob([res.data], {
    //         type: `application/msword`
    //       });
    //       let href = window.URL.createObjectURL(blob); //创建新的URL表示指定的blob对象
    //       const a = document.createElement("a"); //创建a标签
    //       a.style.display = "none";
    //       a.href = href; // 指定下载'链接
    //       console.log(a.href);
    //       a.download = url.substring(url.lastIndexOf("/") + 1); //指定下载文件名
    //       a.click(); //触发下载
    //       URL.revokeObjectURL(a.href); //释放URL对象
    //       document.body.removeChild(a); //释放标签
    //     })
    //     .catch(err => {
    //       //this.$message.error('文件下载失败')
    //     });
    // },

    // ============================新加=======================================

    //上传行业动态pdf (上传文件之前的钩子，参数为上传的文件)
    async beforeUploadprods(file) {
      this.file = file;
      this.filename = file.name;
      // 要求上传格式
      let format = file.name.split(".")[1];

      let formatList = ["doc", "docx", "xls", "xlsx", "ppt", "pptx", "pdf"];

      const extension = formatList.indexOf(format);
      if (extension == -1) {
        this.$message.warning("上传模板不支持此格式");
        return;
      }
      // 异步读取文件机制
      let reader = new FileReader();
      // 读取文件内容，结果用data:url的字符串形式表示
      reader.readAsDataURL(file);

      let that = this;
      // readAsDataURL 方法会读取指定的 Blob 或 File 对象，并生成data URl(base64编码)
      reader.onload = function() {
        that.fileData = reader.result;
      };
      return false;
    },
    //上传行业动态
    uploadprods(params, index) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      /* 直接调用 cos sdk 的方法 */
      cos
        .uploadFile({
          Bucket: "ginseng-wisdom-1300257084" /* 填写自己的 bucket，必须字段 */,
          Region: "ap-nanjing" /* 存储桶所在地域，必须字段 */,
          Key:
            "system_img/" +
            params.file
              .name /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */,
          Body: params.file, // 上传文件对象
          SliceSize:
            1024 *
            1024 *
            500 /* 触发分块上传的阈值，超过5MB 使用分块上传，小于5MB使用简单上传。可自行设置，非必须 */
        })
        .then(res => {
          if (res.statusCode === 200) {
            // this.detailedInfo.productFileList[index].name = res.Location;
            // vue2无法监控数组的改变
            this.$set(this.form.policyFiles, index, res.Location);
            loading.close();
          }
        })
        .catch(err => {
          loading.close();
          console.log("上传出错", err);
        });
    }
  }
};
</script>

<style lang="less" scoped>
.form {
  background-color: #fff;
  padding: 20px 20px 0 20px;
  .el-tag + .el-tag {
    margin-left: 10px;
  }
  .button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
  }
}
.file_flex {
  .add-btn {
    color: #4e93fb;
    cursor: pointer;
  }
  .del-btn {
    color: #ff7575;
    cursor: pointer;
  }
  .down-btn {
    font-size: 20px;
    margin-left: 5px;
    cursor: pointer;
  }
}
/deep/.el-upload--picture-card {
  width: 80px !important;
  height: 80px !important;
  line-height: 80px !important;
}
.policyData {
  display: flex;
  // justify-content: space-between;
}

/deep/ .el-input-group__append,
.el-input-group__prepend {
  background-color: #fff;
  font-size: 20px;
}
/deep/.upload-demo {
  width: 10px;
}
::v-deep .lager-input .el-input__inner {
  width: 656px;
}
::v-deep .lager-input .el-textarea__inner {
  width: 656px;
}
.col_flex-row {
  display: flex;
  align-items: center;

  ::v-deep .el-radio {
    margin-right: 0px;
  }
  ::v-deep .el-radio__label {
    visibility: hidden;
  }
}
</style>
