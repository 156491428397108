<template>
  <div>
    <div class="caseDataParent">
      <div class="caseDataParent_type">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="基本信息" name="basicTab">
            <basic-info ref="basicTabRef"></basic-info>
            <div class="ess_people" v-if="personType">
              <div class="ess_people_list">
                创建人：{{ info.createName || "-" }}
              </div>
              <div class="ess_people_list">
                修改人：{{ info.updateName || "-" }}
              </div>
              <div class="ess_people_list">
                创建时间：{{ info.createTime || "-" }}
              </div>
              <div class="ess_people_list">
                修改时间：{{ info.updateTime || "-" }}
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="手动标签" name="tagTab">
            <labelManagement
              ref="labelManagement"
              :policyId="id"
              :personDataId="personDataId"
            />
          </el-tab-pane>
          <el-tab-pane label="半自动标签" name="autoTab">
            <robotizationlabel
              ref="robotizationlabel"
              :policyId="id"
              :personDataId="personDataId"
            />
          </el-tab-pane>
        </el-tabs>

        <div
          style="height: 50px; background: #fff; border-top: 1px solid #dbdbdb;"
          v-if="this.activeName == 'autoTab'"
        ></div>
        <div class="bottom" v-if="activeName !== 'autoTab'">
          <el-button type="primary" @click="saveItem">保存</el-button>
          <el-button @click="cancelItem">取消</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BasicInfo from "./BasicInfo.vue";
import labelManagement from "./labelManagement.vue";
import robotizationlabel from "./robotizationlabel.vue";
// 基本信息新增 / 基本信息编辑 / 基本信息查询
// 标签信息新增 / 标签信息查询
// 手动标签新增
import {
  insertPolicyDetail,
  editPolicyDetail,
  getPolicyDetail,
  editPolicyKeywordLabel,
  getPolicyKeywordLabel,
  insertHandLabel
} from "@/api/policy.js";

export default {
  name: "PolicyDataIndex",
  components: {
    BasicInfo,
    labelManagement,
    robotizationlabel
  },
  data() {
    return {
      activeName: "basicTab", // 当前标签页
      id: null, // 数据唯一id（新增基本信息得到）
      info: {} //详情
    };
  },
  computed: {
    // 0 新增 1 编辑 （点击编辑按钮传过来的）
    personType() {
      return Number(this.$route.query.personType);
    },
    // 数据唯一id（编辑带的）
    personDataId() {
      return Number(this.$route.query.id);
    }
  },
  watch: {
    // 监听是否在手动标签页面
    activeName: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal && newVal == "tagTab") {
          this.$refs.labelManagement.getBylabelId();
        }
      }
    }
  },
  created() {
    this.$nextTick(() => {
      if (this.personType) {
        this.getPolicyKeywordLabel();
        this.getInfo();
      }
    });
  },
  methods: {
    // 切换tabs页(切换到手动标签,调用查询回显的数据)
    handleClick(tab, event) {
      if (tab.index == 3 && this.id) {
        this.$refs.labelManagement.getBylabelId();
      }
    },
    //基本信息保存
    async handleBasicInfoSave() {
      const isValid = this.$refs.basicTabRef.validateForm();
      if (isValid) {
        const basicInfo = this.$refs.basicTabRef.form;
        //处理基本信息数据
        const isEdit = this.personType || this.id;
        let params = { ...basicInfo };
        let res;
        //基本信息新增
        if (isEdit) {
          res = await editPolicyDetail({
            ...params,
            id: this.personDataId
          });
        } else {
          //基本信息编辑
          res = await insertPolicyDetail(params);
        }
        if (res.code == 200) {
          this.$message.success(isEdit ? "操作成功" : "新增成功");
          if (!isEdit) {
            this.id = res.data;
            this.info.id = res.data;
            this.activeName = "tagTab";
          }
        } else {
          this.$message.error(res.data.message);
        }
      }
    },
    // 保存
    saveItem() {
      switch (this.activeName) {
        case "basicTab":
          this.handleBasicInfoSave();
          break;
        case "tagTab":
          if (this.personDataId) {
            let query = [];

            this.$refs.labelManagement.radiolist.forEach(el => {
              if (el.labelparam.labelNameList.length > 0) {
                el.labelparam.companyId = this.id ? this.id : this.personDataId;
                query.push(el.labelparam);
              }
            });
            if (query.length > 0) {
              if (!this.$refs.labelManagement.labeList.length) {
                insertHandLabel(query).then(response => {
                  if (response.code == 200) {
                    this.$message.success("保存成功");
                    this.activeName = "autoTab";
                  } else {
                    this.$message.error(response.data.message);
                  }
                });
              } else {
                insertHandLabel(query).then(response => {
                  if (response.code == 200) {
                    this.$message.success("编辑成功");
                    this.activeName = "autoTab";
                  } else {
                    this.$message.error(response.data.message);
                  }
                });
              }
            } else {
              this.$message.warning("请勾选标签！");
            }
          } else {
            if (!this.id) {
              this.$message.error("请先保存基本信息的数据再进行下一步操作！");
            } else {
              let query = [];

              this.$refs.labelManagement.radiolist.forEach(el => {
                if (el.labelparam.labelNameList.length > 0) {
                  el.labelparam.companyId = this.id;
                  query.push(el.labelparam);
                }
              });
              if (query.length > 0) {
                if (!this.$refs.labelManagement.labeList.length) {
                  insertHandLabel(query).then(response => {
                    if (response.code == 200) {
                      this.$message.success("保存成功");
                      this.activeName = "autoTab";
                    } else {
                      this.$message.error(response.data.message);
                    }
                  });
                } else {
                  insertHandLabel(query).then(response => {
                    if (response.code == 200) {
                      this.$message.success("编辑成功");
                      this.activeName = "autoTab";
                    } else {
                      this.$message.error(response.data.message);
                    }
                  });
                }
              } else {
                this.$message.warning("请勾选标签！");
              }
            }
          }

          break;
        case "autoTab":
          break;

        default:
          break;
      }
    },
    // 取消按钮
    cancelItem() {
      this.$router.push({
        path: "/policyData"
      });
    },
    // 报告基本信息详情
    async getInfo() {
      const res = await getPolicyDetail({ id: this.personDataId });
      if (res.code == 200) {
        this.info = res.data;
        this.id = res.data ? res.data.id : null;
        //基本信息渲染
        this.$refs.basicTabRef.handleFormRender(res.data);
      }
    },
    // 政策标签信息详情
    async getPolicyKeywordLabel() {
      const res = await getPolicyKeywordLabel({
        id: this.personDataId
      });
      if (res.code == 200) {
        // 如果请求的标签数据为null的情况，就处理下
        if (!res.data.reportKeywordAddParamList) {
          this.$refs.labelInfo.reportKeywordLabelResultParam = {
            reportId: "", // 案例主键
            keywordVersion: "", // 关键字版本
            // type: null,
            reportKeywordAddParamList: [
              {
                reportLabelList: [
                  {
                    groupLabelId: "", // 标签组编号
                    groupLabelName: "", // 标签组名称
                    labelId: "", // 标签编号
                    labelName: "", // 标签内容
                    reportKeywordId: null,
                    id: null,
                    superiorLabel: null // 上级标签
                  }
                ],
                keyword: "", // 关键词
                keywordDescribe: "" // 关键词数据
              }
            ]
          };
        } else {
          this.$refs.labelInfo.reportKeywordLabelResultParam = res.data;
          // 请求数据回来后，将标签内容也改为（标签组名称+标签内容）
          res.data.reportKeywordAddParamList.forEach(item => {
            item.reportLabelList.forEach(child => {
              child.labelName = child.groupLabelName + "/" + child.labelName;
            });
          });
        }
      }
    }
  }
};
</script>

<style lang="less" scoped>
.caseDataParent_type {
  padding: 0 20px;
}
.caseDataParent {
  overflow: hidden;
  background-color: #fff;
  /deep/ .el-tabs__content {
    overflow: auto;
  }
}
.bottom {
  border-top: 1px solid #dbdbdb;
  background: #fff;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  .el-button {
    margin: 0 20px;
  }
}
.ess_people {
  display: flex;
  flex-direction: column;
  // overflow: hidden;
  margin: 20px 30px;
  align-content: center;

  .ess_people_list {
    width: 100%;
    margin: 4px 0;
    color: #7c7f8e;
    font-size: 12px;
  }
}
</style>
