<template>
  <div
    class="my-radio-multi"
    :class="{ 'style-button': isStyleButton, collapse: isShowCollapseBtn }"
    :style="{ width: width, ...heightStyle }"
  >
    <div class="my-radio-multi_header" ref="myGroupBox">
      <el-row>
        <el-col :span="22">
          <div class="group-box">
            <!-- 圆圈形式展示 -->
            <el-radio-group
              v-model="currentVal"
              @change="onChange"
              v-if="!isStyleButton"
            >
              <el-radio :label="null" v-show="isShowAllTag">{{
                allText
              }}</el-radio>
              <el-radio
                v-for="(option, ind) in optionDatas"
                :label="option[valueField]"
                :key="ind"
                >{{ option[labelField] }}</el-radio
              >
            </el-radio-group>
            <!-- 按钮形式展示 -->
            <el-radio-group
              v-model="currentVal"
              @change="onChange"
              v-if="isStyleButton"
            >
              <el-radio-button :label="null" v-show="isShowAllTag">{{
                allText
              }}</el-radio-button>
              <el-radio-button
                v-for="(option, ind) in optionDatas"
                :label="option[valueField]"
                :key="ind"
                >{{ option[labelField] }}</el-radio-button
              >
            </el-radio-group>
          </div>
        </el-col>
        <el-col :span="2" class="group-box_btn">
          <el-button
            type="text"
            v-show="isShowCollapseBtn"
            @click="onClickOpen()"
            ><span>{{ collapseTxt }}</span>
            <i
              class="el-icon-arrow-down"
              style="font-weight: bold"
              :class="{ icon_open: isOpen, icon_unopen: !isOpen }"
            ></i>
          </el-button>
        </el-col>
      </el-row>
      <div
        class="my-radio-multi_content"
        v-if="!_.isEmpty(currentVal) && !_.isEmpty(childrenArray)"
      >
        <span class="child—txt">子标签：</span>
        <span class="child—txt" v-show="_.isEmpty(childrenArray)"
          >暂无数据</span
        >
        <!-- 单选框 -->
        <div v-if="presentationType == 'radio'">
          <!-- 圆圈形式展示 -->
          <el-radio-group
            v-model="currentChildVals[0]"
            @change="onChangeChild"
            v-if="!isStyleButton"
          >
            <el-radio
              v-for="(child, ind) in childrenArray"
              :label="child[valueField]"
              :key="ind"
              >{{ child[labelField] }}</el-radio
            >
          </el-radio-group>
          <!-- 按钮形式展示 -->
          <el-radio-group
            v-model="currentChildVals[0]"
            @change="onChangeChild"
            v-if="isStyleButton"
          >
            <el-radio-button
              v-for="(child, ind) in childrenArray"
              :label="child[valueField]"
              :key="ind"
              >{{ child[labelField] }}</el-radio-button
            >
          </el-radio-group>
        </div>
        <!-- 多选框 -->
        <div v-else>
          <!-- 圆圈形式展示 -->
          <el-checkbox-group
            v-model="currentChildVals"
            @change="onChangeChild"
            v-if="!isStyleButton"
          >
            <el-checkbox
              v-for="(child, ind) in childrenArray"
              :label="child[valueField]"
              :key="ind"
              >{{ child[labelField] }}</el-checkbox
            >
          </el-checkbox-group>
          <!-- 按钮形式展示 -->
          <el-checkbox-group
            v-model="currentChildVals"
            @change="onChangeChild"
            v-if="isStyleButton"
          >
            <el-checkbox-button
              v-for="(child, ind) in childrenArray"
              :label="child[valueField]"
              :key="ind"
              >{{ child[labelField] }}</el-checkbox-button
            >
          </el-checkbox-group>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import myFormSearchMixin from "../mixins";

export default {
  name: "MyRadioMulti",
  mixins: [myFormSearchMixin],
  props: {
    //options 来源 如果是dic 则从字典中取或者 从接口取
    optionsOrigin: {
      type: String,
      default: "dic"
    },
    //optionsOrigin不是dic的话 数据从这里取
    options: {
      type: Array,
      default: () => []
    },
    //当前选中的key
    selectedKey: {
      type: [String, Number, null],
      default: null
    },
    //选中的子标签的keys
    selectedChildKeys: {
      type: Array,
      default: () => []
    },
    //字典编号
    typeCode: {
      type: [String, Number, null],
      default: ""
    },
    //单前选中的对象
    curNode: {
      type: Object,
      default: () => {}
    },
    //子标签展示形式
    presentationType: {
      type: String, //checkbox|radio
      default: "radio" //默认radio
    },
    //全部的默认文字
    allText: {
      type: String,
      default: "不限"
    },
    //是否展示全部按钮 默认展示
    isShowAllTag: {
      type: Boolean,
      default: true
    },
    //绑定的value值字段
    valueField: {
      type: String,
      default: "codeName"
    },
    //显示的label字段
    labelField: {
      type: String,
      default: "codeName"
    },
    //展开的默认文字
    collapseTxt: {
      type: String,
      default: "展开全部"
    },
    //是否展示全部显示按钮
    isShowCollapseBtn: {
      type: Boolean,
      default: true
    },
    //--其他不必要的 可自定义
    //为调用的表单字段
    itemKey: {
      // el-tags做标识
      type: String,
      default: ""
    },
    //为调用的表单字段中文类型 前端自定义
    itemType: {
      // el-tags选中的文字
      type: String,
      default: ""
    },
    //组件宽度
    width: {
      type: String,
      default: "100%"
    },
    //是否以按钮形式展示
    isStyleButton: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    selectedKey: {
      handler(val) {
        this.currentVal = val ? val : null;
        this.handleChildArray(val);
      },
      immediate: true
    },
    selectedChildKeys: {
      handler(val) {
        this.currentChildVals = val ? val : [];
      },
      immediate: true
    },
    //当前选中节点 清空时可用到
    curNode: {
      handler(val) {
        if (_.isEmpty(val)) {
          this.resetDatas();
        }
      }
    },
    options: {
      handler(val) {
        if (this.optionsOrigin !== "dic") {
          this.optionDatas = val;
        }
      },
      deep: true
    }
  },
  data() {
    return {
      heightStyle: {}, //自定义
      currentVal: "", //选中的单选按钮值
      currentChildVals: this.selectedChildKeys, //选中的单选下面的checkbox值或者radio
      childrenArray: [], //单选下面的child数组
      isOpen: false, //是否展开单选
      //用于回调的对象
      currentValues: {
        id: null, //单选的值
        node: null, //单选的对象
        child: [] //单选选中的child
      },
      optionDatas: []
    };
  },
  created() {
    if (this.optionsOrigin == "dic") {
      this.getOptionDatas();
    } else {
      this.optionDatas = this.options;
    }
    this.handleChildArray(this.selectedKey);
  },
  methods: {
    resetDatas() {
      this.currentVal = null; //选中的单选按钮值
      this.currentChildVals = []; //选中的单选下面的多选
      this.childrenArray = []; //单选下面的child数组
      this.isOpen = false;
      this.currentValues = {
        id: null, //单选的值
        node: null, //单选的对象
        child: [] //单选选中的child
      };
    },

    //获取一级下面的子列表
    handleChildArray(value) {
      this.currentVal = value;
      this.currentChildVals = [];
      const node = value
        ? _.find(this.optionDatas, item => item[this.valueField] === value)
        : null;
      this.childrenArray = node && node.childNode ? node.childNode : [];
      this.currentValues = {
        id: value,
        node: node,
        child: []
      };
    },
    // 单选onchange
    onChange(value) {
      this.handleChildArray(value);
      /**
       * id 为选中value 值
       * node 为选中的对象
       * child 为选中的子复选框
       */
      this.$emit("update:value", value);
      this.$emit("callback", this.currentValues);
      //如果有子标签需要计算子标签撑开的高度
      this.delayCalcGroupBoxHeight();
    },
    // 多选/单选onchange
    onChangeChild(values) {
      const isArray = Array.isArray(values);
      this.currentChildVals = isArray ? values : [values];

      this.currentValues = {
        ...this.currentValues,
        child: isArray ? values : [values]
      };
      this.$emit("callback", this.currentValues);
    }
  }
};
</script>
<style lang="less" scoped>
@lightBgColor: #d8eaff;
@primaryColor: #0c69e3;
.collapse {
  height: 36px;
  overflow: hidden;
}
.my-radio-multi {
  &_header {
    .group-box {
      &_btn {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        span {
          font-size: 12px;
          margin-right: 2px;
        }
      }
    }
    .icon_open {
      padding-top: 2px;
      transform: rotate(-180deg); /* 旋转180度 */
      transition: all 0.2s linear;
    }
    .icon_unopen {
      transition: all 0.2s linear;
    }

    .el-radio {
      height: 36px;
      line-height: 36px;
    }
  }
  &_content {
    display: flex;
    background-color: #f5f6f7;
    padding: 0 4px 4px 4px;
    border-radius: 4px;
    width: 100%;
    min-height: 44px;
    margin-top: 4px;
    .child—txt {
      color: #606266;
      display: inline-block;
      min-width: 60px;
      height: 36px;
      margin-top: 4px;
    }
    ::v-deep .el-radio {
      margin-top: 4px;
    }
  }
}
.style-button {
  ::v-deep .el-radio-button__inner {
    border: none;
    border-radius: 4px;
    font-size: 14px;
    padding: 0 8px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    margin-right: 4px;
    font-weight: normal;
    margin-top: 4px;
  }
  ::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    color: @primaryColor;
    background-color: @lightBgColor;
    box-shadow: unset;
  }
  ::v-deep .el-checkbox-button__inner {
    border: none;
    border-radius: 4px;
    font-size: 14px;
    padding: 0 8px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    margin-right: 4px;
    font-weight: normal;
    margin-top: 4px;
  }
  ::v-deep .el-checkbox-button.is-checked .el-checkbox-button__inner {
    color: @primaryColor;
    background-color: @lightBgColor;
    box-shadow: unset;
  }
}
</style>
