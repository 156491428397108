import request from '@/utils/request'

// 查询
export function getPolicyList(data) {
  return request({
    url: '/system/policy/getPolicyList',
    method: 'post',
    data
  })
}

// 查询单个
export function getPolicy(data) {
  return request({
    url: '/system/policy/getPolicyById',
    method: 'get',
    params: data
  })
}

// 新增
export function addPolicy(data) {
  return request({
    url: '/system/policy/save',
    method: 'post',
    data
  })
}

// 修改
export function updatePolicy(data) {
  return request({
    url: '/system/policy/updatePolicy',
    method: 'post',
    data
  })
}

// 删除
export function deletePolicy(data) {
  return request({
    url: '/system/policy/deletePolicyById',
    method: 'post',
    data
  })
}

// 导入
export function policyImportProject(data) {
  return request({
    url: '/system/policy/importProject',
    method: 'post',
    data
  })
}

// 修改后的政策数据上传（刘议）
export function importExcelV1(data) {
  return request({
    url: '/system/policy/v1/importExcel',
    method: 'post',
    data
  })
}

// ----------------------------------------------

// 基本信息新增
export function insertPolicyDetail(data) {
  return request({
    url: '/system/policy/insertPolicyDetail',
    method: 'post',
    data
  })
}

// 基本信息编辑
export function editPolicyDetail(data) {
  return request({
    url: '/system/policy/editPolicyDetail',
    method: 'post',
    data
  })
}

// 基本信息查询
export function getPolicyDetail(data) {
  return request({
    url: '/system/policy/getPolicyDetail',
    method: 'get',
    params: data
  })
}

// ---------------------

// 标签信息新增
export function editPolicyKeywordLabel(data) {
  return request({
    url: '/system/policy/editPolicyKeywordLabel',
    method: 'post',
    data
  })
}

// 标签信息查询
export function getPolicyKeywordLabel(data) {
  return request({
    url: '/system/policy/getPolicyKeywordLabel',
    method: 'get',
    params: data
  })
}

// -------------------

// 手动标签新增
export function insertHandLabel(data) {
  return request({
    url: '/system/policy/insertHandLabel',
    method: 'post',
    data
  })
}

// 手动标签查询
export function getById(data) {
  return request({
    url: '/system/policy/getById',
    method: 'get',
    params: data
  })
}

// ------------------------

// 半自动标签新增
export function insertMatchPolicyLabel(data) {
  return request({
    url: '/system/policy/label/insertMatchPolicyLabel',
    method: 'post',
    data
  })
}

// 半自动标签查询
export function getMatchLabel(data) {
  return request({
    url: '/system/policy/label/getMatchLabel',
    method: 'get',
    params: data
  })
}
