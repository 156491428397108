<template>
  <div
    class="my-checkbox"
    :class="[{ collapse: isShowCollapseBtn }]"
    :style="heightStyle"
  >
    <div class="my-radio_header" ref="myGroupBox">
      <el-row>
        <el-col :span="22">
          <div class="group-box">
            <el-checkbox
              v-model="isCheckedAll"
              v-show="isShowAllTag"
              @change="onChangeAll"
              >{{ allText }}</el-checkbox
            >
            <el-checkbox-group v-model="currentVal" @change="onChange">
              <el-checkbox
                v-for="(option, ind) in optionDatas"
                :label="option[valueField]"
                :key="ind"
                >{{ option[labelField] }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
        </el-col>
        <el-col :span="2" class="group-box_btn">
          <el-button
            type="text"
            v-show="isShowCollapseBtn"
            @click="onClickOpen()"
            ><span>{{ collapseTxt }}</span>
            <i
              class="el-icon-arrow-down"
              style="font-weight: bold"
              :class="{ icon_open: isOpen, icon_unopen: !isOpen }"
            ></i>
          </el-button>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import myFormSearchMixin from "../mixins";
export default {
  name: "MyCheckbox",
  mixins: [myFormSearchMixin],
  props: {
    //options 来源 如果是dic 则从字典中取 local则取options
    optionsOrigin: {
      type: String,
      default: "dic"
    },
    //optionsOrigin不是dic的话 数据从这里取
    options: {
      type: Array,
      default: () => []
    },
    //字典编号
    typeCode: {
      type: [String, Number, null],
      default: ""
    },
    //当前选中的key
    selectedKeys: {
      type: Array,
      default: () => []
    },
    //绑定的value值字段
    valueField: {
      type: String,
      default: "codeName"
    },
    //显示的label字段
    labelField: {
      type: String,
      default: "codeName"
    },
    //全部的默认文字
    allText: {
      type: String,
      default: "不限"
    },
    //是否展示全部 默认展示
    isShowAllTag: {
      type: Boolean,
      default: true
    },
    //展开的默认文字
    collapseTxt: {
      type: String,
      default: "展开全部"
    },
    //是否展示全部显示按钮
    isShowCollapseBtn: {
      type: Boolean,
      default: false
    },
    //--其他不必要的 可自定义
    //为调用的表单字段
    itemKey: {
      // el-tags做标识
      type: String,
      default: ""
    },
    //为调用的表单字段中文类型 前端自定义
    itemType: {
      // el-tags选中的文字
      type: String,
      default: ""
    }
  },
  watch: {
    selectedKeys: {
      handler(val) {
        this.currentVal = val ? val : [];
      },
      immediate: true
    },
    options: {
      handler(val) {
        if (this.optionsOrigin !== "dic") {
          this.optionDatas = val;
        }
      },
      deep: true
    }
  },
  data() {
    return {
      currentVal: [],
      isCheckedAll: true,
      optionDatas: [],
      isOpen: false,
      heightStyle: {} //自定义
    };
  },
  created() {
    if (this.optionsOrigin == "dic") {
      this.getOptionDatas();
    } else {
      this.optionDatas = this.options;
    }
  },
  methods: {
    //选择全部
    onChangeAll(value) {
      if (value) {
        this.currentVal = [];
        this.$emit("update:value", []);
        this.$emit("callback", { ids: [], node: [] });
      }
      this.isCheckedAll = value;
    },
    onChange(value) {
      if (this.isCheckedAll) {
        this.isCheckedAll = false;
      }
      let currentValues = _.filter(this.optionDatas, item =>
        _.includes(value, item[this.valueField])
      );

      this.$emit("update:value", value);
      /**
       * value 为选中value 值
       * nodeValues 为选中的对象集合
       */
      this.$emit("callback", { ids: value, nodes: currentValues });
    }
  }
};
</script>
<style lang="less" scoped>
@lightBgColor: #d8eaff;
@primaryColor: #0c69e3;
.collapse {
  height: 36px;
  overflow: hidden;
}
.my-checkbox {
  .group-box {
    display: flex;

    &_btn {
      display: flex;
      justify-content: flex-end;
      span {
        font-size: 12px;
        margin-right: 2px;
      }
    }
  }
  .icon_open {
    padding-top: 2px;
    transform: rotate(-180deg); /* 旋转180度 */
    transition: all 0.2s linear;
  }
  .icon_unopen {
    transition: all 0.2s linear;
  }
}
</style>
