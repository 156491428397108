import {
  getDicRadioButton,
  getKnowTechList,
  getIndustryList,
  getKnowFieldList,
  policyTypsOptionsApi,
  parkTypeOptionsApi,
  productCategoryApi,
  rewardMethodOptionsApi,
  industryTypeApi
} from "@/api/common";
import { mapState, mapMutations, mapActions } from "vuex";
let myFormSearchMixin = {
  computed: {
    ...mapState("mySearchSlot", ["isOpenMySearch", "calcHeightCount"])
  },
  methods: {
    ...mapMutations("mySearchSlot", ["SET_OPENMYSEARCH", "SET_ISCALCHEIGHT"]),
    onClickOpen() {
      this.isOpen = !this.isOpen;
      if (this.isOpen) {
        const height = this.$refs.myGroupBox.offsetHeight;
        this.heightStyle = {
          height: `${height}px`,
          transition: `all .3s linear`
        };
      } else {
        this.heightStyle = {
          height: `36px`,
          transition: `all .3s linear`
        };
      }
      // console.log('height:::', ref.offsetHeight);
      // if (!this.isOpenMySearch) {
      // 	this.SET_OPENMYSEARCH(true);
      // }
      // this.SET_ISCALCHEIGHT();
    },
    //延迟动态计算盒子高度
    delayCalcGroupBoxHeight() {
      setTimeout(() => {
        const height = this.$refs.myGroupBox.offsetHeight;
        this.heightStyle = {
          height: `${height}px`,
          transition: `all .3s linear`
        };
      }, 0);
    },
    //options 接口数据
    async getOptionDatas() {
      switch (this.itemType) {
        case "行业":
          const industryStr = sessionStorage.getItem("industry");
          if (!industryStr) {
            let { data: res } = await getIndustryList();
            res = JSON.parse(
              JSON.stringify(res).replace(/industryName/g, "codeName")
            );
            sessionStorage.setItem("industry", JSON.stringify(res));
          }
          this.optionDatas = JSON.parse(sessionStorage.getItem("industry"));
          break;
        case "领域":
          const fieldStr = sessionStorage.getItem("field");
          if (!fieldStr) {
            let { data: res } = await getKnowFieldList();
            res = JSON.parse(
              JSON.stringify(res).replace(/fieldName/g, "codeName")
            );
            sessionStorage.setItem("field", JSON.stringify(res));
          }
          this.optionDatas = JSON.parse(sessionStorage.getItem("field"));
          break;
        case "技术":
          const techStr = sessionStorage.getItem("tech");
          if (!techStr) {
            let { data: res } = await getKnowTechList();
            res = JSON.parse(
              JSON.stringify(res).replace(/techName/g, "codeName")
            );
            sessionStorage.setItem("tech", JSON.stringify(res));
          }
          this.optionDatas = JSON.parse(sessionStorage.getItem("tech"));
          break;
        case "园区类型":
          const parkTypsStr = sessionStorage.getItem("parkTyps");
          if (!parkTypsStr) {
            let { data: res } = await parkTypeOptionsApi();
            res = res.map(item => {
              item.childNode = item.label ? item.label : [];
              return item;
            });
            res = JSON.parse(JSON.stringify(res));
            sessionStorage.setItem("parkTyps", JSON.stringify(res));
          }
          this.optionDatas = JSON.parse(sessionStorage.getItem("parkTyps"));
          break;
        case "产品类别":
          const productCategoryStr = sessionStorage.getItem("productCategory");
          if (!productCategoryStr) {
            let { data: res } = await productCategoryApi({
              code: "productCategory"
            });
            res = JSON.parse(JSON.stringify(res));
            sessionStorage.setItem("productCategory", JSON.stringify(res));
          }
          this.optionDatas = JSON.parse(
            sessionStorage.getItem("productCategory")
          );
          break;
        case "服务类型":
          const serviceTypeStr = sessionStorage.getItem("serviceType");
          if (!serviceTypeStr) {
            let { data: res } = await serviceTypeOptionsApi();
            sessionStorage.setItem("serviceType", JSON.stringify(res));
          }
          this.optionDatas = JSON.parse(sessionStorage.getItem("serviceType"));
          break;
        case "产业机构":
          const industryTypeStr = sessionStorage.getItem("industryType");
          if (!industryTypeStr) {
            let { data: res } = await industryTypeApi();
            res = JSON.parse(JSON.stringify(res));
            sessionStorage.setItem("industryType", JSON.stringify(res));
          }
          this.optionDatas = JSON.parse(sessionStorage.getItem("industryType"));
          break;

        default:
          //默认根据传入的code查询
          const optStr = sessionStorage.getItem(this.typeCode);
          if (!optStr) {
            let { data: res } = await getDicRadioButton({
              code: this.typeCode
            });
            if (this.typeCode == "041") {
              res = JSON.parse(
                JSON.stringify(res).replace(
                  /internationalStandard/g,
                  "codeName"
                )
              );
            }
            sessionStorage.setItem(this.typeCode, JSON.stringify(res));
          }
          this.optionDatas = JSON.parse(sessionStorage.getItem(this.typeCode));
          break;
      }
    }
  }
};
export default myFormSearchMixin;
